import { MessageDiv, StyledArticle } from '../styles';

import { Layout } from '../components/Layout';
import React from 'react';
import { StyledH2 } from '../styles/common';
import { SEO } from '../components/SEO';
export const Head = () => (
	<SEO 
		title='Deklaracja dostępności'
		description='Deklaracja dostępności.'
	/>);
const DeklaracjaPage = () => (
	<Layout>
		<StyledArticle>
			<StyledH2 as="h1" color="info">
				Deklaracja dostępności
			</StyledH2>
			<MessageDiv>
				<h3>Oświadczenie w sprawie dostępności</h3>
				<div id="a11y-wstep">
					<span id="a11y-podmiot">
						Miejskie Zakłady Komunikacyjne Sp. z o.o. z siedzibą w Bydgoszczy
					</span>
					&nbsp;zobowiązują się zapewnić dostępność swojej strony internetowej
					zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej
					stron internetowych i aplikacji mobilnych podmiotów publicznych.
					Oświadczenie w sprawie dostępności ma zastosowanie do strony
					internetowej{' '}
					<a id="a11y-url" href="https://mzk.bydgoszcz.pl">
						mzk.bydgoszcz.pl
					</a>
					.
				</div>
				<div>
					Data publikacji strony internetowej:&nbsp;
					<span id="a11y-data-publikacja">2015-06-01</span>. Data ostatniej
					istotnej aktualizacji:&nbsp;
					<span id="a11y-data-aktualizacja">2020-09-21</span>.
				</div>
				<h3 id="a11y-status" className="text-primary">
					Status pod względem zgodności z ustawą
				</h3>
				<div>
					Strona internetowa jest zgodna z ustawą z dnia 4 kwietnia 2019 r. o
					dostępności cyfrowej stron internetowych i aplikacji mobilnych
					podmiotów publicznych.
				</div>

				<div>
					Oświadczenie sporządzono:&nbsp;
					<span id="a11y-data-sporzadzenie">2020-09-21</span>
				</div>
				<div>
					Deklarację sporządzono na podstawie samooceny przeprowadzonej przez
					Miejskie Zakłady Komunikacyjne Sp. z o.o. z siedzibą w Bydgoszczy.
				</div>

				<h3 id="a11y-kontakt" className="text-primary">
					Informacje zwrotne i dane kontaktowe
				</h3>
				<div>
					W przypadku problemów z dostępnością strony internetowej prosimy o
					kontakt. Osobą kontaktową jest&nbsp;
					<span id="a11y-osoba">Katarzyna Rybicka</span>,&nbsp;
					<a id="a11y-email" href="krybicka@mzk.bydgoszcz.pl">
						krybicka@mzk.bydgoszcz.pl
					</a>
					. Kontaktować można się także dzwoniąc na numer telefonu&nbsp;
					<a href="tel:48523249441" id="a11y-telefon">
						52 324 94 41
					</a>
					. Tą samą drogą można składać wnioski o udostępnienie informacji
					niedostępnej oraz składać skargi na brak zapewnienia dostępności.
				</div>
				<h3 id="a11y-procedura" className="text-primary">
					Informacje na temat procedury
				</h3>
				<div>
					Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności
					cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich
					elementu. Można także zażądać udostępnienia informacji za pomocą
					alternatywnego sposobu dostępu, na przykład przez odczytanie
					niedostępnego cyfrowo dokumentu, opisanie zawartości filmu bez
					audiodeskrypcji itp. Żądanie powinno zawierać dane osoby zgłaszającej
					żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną
					chodzi oraz sposób kontaktu. Jeżeli osoba żądająca zgłasza potrzebę
					otrzymania informacji za pomocą alternatywnego sposobu dostępu,,
					powinna także określić dogodny dla niej sposób przedstawienia tej
					informacji. Podmiot publiczny powinien zrealizować żądanie
					niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z
					żądaniem. Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot
					publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy
					realizacja żądania będzie możliwa, przy czym termin ten nie może być
					dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem. Jeżeli
					zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny
					może zaproponować alternatywny sposób dostępu do informacji. W
					przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia
					dostępności lub alternatywnego sposobu dostępu do informacji, wnoszący
					żądanie możne złożyć skargę w sprawie zapewniana dostępności cyfrowej
					strony internetowej, aplikacji mobilnej lub elementu strony
					internetowej, lub aplikacji mobilnej. Po wyczerpaniu wskazanej wyżej
					procedury można także złożyć wniosek do&nbsp;
					<a href="https://www.rpo.gov.pl/">Rzecznika Praw Obywatelskich.</a>
				</div>
				<h3 id="a11y-architektura" className="text-primary">
					Dostępność architektoniczna
				</h3>
				<div>
					Interesanci przyjmowani są w Dziale Nadzoru Ruchu.
					<br />
					Opis dojścia do Działu Nadzoru Ruchu:
					<ol className="number-list">
						<li>
							Dział Nadzoru Ruchu znajduje się na wysokim parterze. Wejście do
							niego znajduje się od zachodniej strony budynku administracyjnego
							przy ul. Inowrocławskiej 11 w Bydgoszczy.
						</li>
						<li>
							Teren przy budynku (od strony zachodniej) jest ogrodzony, znajduje
							się na nim parking dla samochodów osobowych. Nawierzchnia terenu
							jest z kostki betonowej.
						</li>
						<li>
							W ogrodzeniu usytuowane są: bramka – przejście dla pieszych i
							szlaban – wjazd dla samochodów.
						</li>
						<li>Przed bramką jest chodnik betonowy.</li>
						<li>
							Bramka jest o szerokości 94cm. Bezpośrednio za bramką jest próg o
							wysokości 8cm, który tworzą płyty granitowe o szerokości 100 cm i
							długości 117 cm.
						</li>
						<li>
							W odległości 117cm od bramki znajdują się schody betonowe
							(wyłożone płytami granitowymi) o szerokości 100cm – trzy stopnie,
							każdy o wysokości 16cm i szerokości (głębokości) 36cm. Po obu
							stronach schodów są betonowe murki oporowe o wysokości 58cm. Po
							lewej stronie schodów (od strony bramki) na wysokości 110 cm
							znajduje się metalowa poręcz.
						</li>
						<li>
							W odległości ok. 300cm od schodów opisanych w p.6 znajdują się
							kolejne betonowe schody (wyłożone płytami granitowymi) – wejściowe
							do Działu Nadzoru Ruchu.
						</li>
						<li>
							Schody wejściowe są o szerokości 206cm – 8 stopni, każdy stopień o
							szerokości (głębokości) 32cm i wysokości 16cm. Ostatni ósmy
							stopień (górny) wchodzi w skład podestu o wymiarach: szerokość
							206cm, długość (głębokość) 180cm.
						</li>
						<li>
							Po obu stronach schodów i podestu są metalowe balustrady o
							wysokości 110cm.&nbsp;
						</li>
						<li>
							Z podestu, poprzez przeszklone drzwi (205/110cm), bez progu,
							wchodzi się do korytarza. Po obu stronach korytarza znajdują się
							pomieszczenia biurowe i sanitarne Działu Nadzoru Ruchu.
						</li>
					</ol>
				</div>
			</MessageDiv>
		</StyledArticle>
	</Layout>
);

export default DeklaracjaPage;
